import { siteConfig } from '../config/site.config'

export default function ContractInfo() {
    const contract = siteConfig.contract
    const wikiUrl = siteConfig.content.wiki.url

    return (
        <section id="about" className="py-16 bg-ugandan-black text-ugandan-white">
            <div className="container mx-auto px-4">
                <div className="max-w-3xl mx-auto text-center font-arcade">
                    <h2 className="text-3xl font-bold mb-6">Contract Information</h2>
                    <div className="bg-ugandan-red bg-opacity-20 p-6 rounded-lg">
                        <p className="text-sm mb-2">Contract Address:</p>
                        <div className="flex items-center justify-center gap-2">
                            <code className="bg-ugandan-black px-4 py-2 rounded text-lg font-retro gap-2 flex flex-col sm:flex-row items-center">
                                <span className="text-center sm:text-left">Solana</span>
                                <br />
                                <span className="break-all text-center sm:text-left">{contract.addressSolana}</span>
                                <button
                                    onClick={() => navigator.clipboard.writeText(contract.addressSolana)}
                                    className="bg-ugandan-black rounded hover:bg-opacity-80 transition-opacity mt-2 sm:mt-0"
                                    title="Copy address"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                                        <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                                    </svg>
                                </button>
                            </code>
                            <code className="bg-ugandan-black px-4 py-2 rounded text-lg font-retro gap-2 flex flex-col sm:flex-row items-center">
                                <span className="text-center sm:text-left">Base</span>
                                <br />
                                <span className="break-all text-center sm:text-left">{contract.addressEVM}</span>
                                <button
                                    onClick={() => navigator.clipboard.writeText(contract.addressEVM)}
                                    className="bg-ugandan-black rounded hover:bg-opacity-80 transition-opacity mt-2 sm:mt-0"
                                    title="Copy address"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                                        <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                                    </svg>
                                </button>
                            </code>


                        </div>
                        <p className="mt-6 text-gray-300">
                            {contract.description.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))}
                        </p>
                    </div>

                    {/* TODO: move Wiki Link to bottom of meme carousel or seperate section */}
                    <div className="mt-8">
                        <a
                            href={wikiUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block bg-ugandan-red hover:bg-opacity-90 text-ugandan-white px-6 py-3 rounded-lg transition-all duration-300 text-sm"
                        >
                            {siteConfig.content.wiki.buttonText}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}