import TiktokIcon from '../assets/icons/tiktok.svg'
import TiktokWhiteIcon from '../assets/icons/tiktok-w.svg'
import JupiterIcon from '../assets/icons/jupiter.svg'
import BullxIcon from '../assets/icons/bullx.svg'
import PhotonIcon from '../assets/icons/photon.svg'
import DextoolsIcon from '../assets/icons/dextools.svg'
import SlingshotIcon from '../assets/icons/slingshot.svg'
import SlingshotBlackIcon from '../assets/icons/slingshot-black.svg'
import LinktreeIcon from '../assets/icons/linktree.svg'

const icons = {
    tiktok: TiktokIcon,
    tiktokWhite: TiktokWhiteIcon,
    jupiter: JupiterIcon,
    bullx: BullxIcon,
    photon: PhotonIcon,
    dextools: DextoolsIcon,
    slingshot: SlingshotIcon,
    slingshotBlack: SlingshotBlackIcon,
    linktree: LinktreeIcon,
}

export default function Icon({ name, className }) {
    if (!icons[name]) return null

    return (
        <img
            src={icons[name]}
            alt={`${name} icon`}
            className={`${className || 'w-8 h-8'}`}
        />
    )
}